import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Signpost
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIgM3YzIiAvPgogIDxwYXRoIGQ9Ik0xOC41IDEzaC0xM0wyIDkuNSA1LjUgNmgxM0wyMiA5LjVaIiAvPgogIDxwYXRoIGQ9Ik0xMiAxM3Y4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/signpost
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Signpost = createLucideIcon('Signpost', [
  ['path', { d: 'M12 3v3', key: '1n5kay' }],
  ['path', { d: 'M18.5 13h-13L2 9.5 5.5 6h13L22 9.5Z', key: '27os56' }],
  ['path', { d: 'M12 13v8', key: '1l5pq0' }],
]);

export default Signpost;
