import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ClipboardPen
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cmVjdCB3aWR0aD0iOCIgaGVpZ2h0PSI0IiB4PSI4IiB5PSIyIiByeD0iMSIgLz4KICA8cGF0aCBkPSJNMTAuNCAxMi42YTIgMiAwIDAgMSAzIDNMOCAyMWwtNCAxIDEtNFoiIC8+CiAgPHBhdGggZD0iTTE2IDRoMmEyIDIgMCAwIDEgMiAydjE0YTIgMiAwIDAgMS0yIDJoLTUuNSIgLz4KICA8cGF0aCBkPSJNNCAxMy41VjZhMiAyIDAgMCAxIDItMmgyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/clipboard-pen
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ClipboardPen = createLucideIcon('ClipboardPen', [
  ['rect', { width: '8', height: '4', x: '8', y: '2', rx: '1', key: '1oijnt' }],
  ['path', { d: 'M10.4 12.6a2 2 0 0 1 3 3L8 21l-4 1 1-4Z', key: 'hnx206' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5', key: 'cereej' }],
  ['path', { d: 'M4 13.5V6a2 2 0 0 1 2-2h2', key: '5ua5vh' }],
]);

export default ClipboardPen;
