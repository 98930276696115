import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FilePenLine
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMTggNS0zLTNINmEyIDIgMCAwIDAtMiAydjE2YTIgMiAwIDAgMCAyIDJoMTJhMiAyIDAgMCAwIDItMiIgLz4KICA8cGF0aCBkPSJNOCAxOGgxIiAvPgogIDxwYXRoIGQ9Ik0xOC40IDkuNmEyIDIgMCAxIDEgMyAzTDE3IDE3bC00IDEgMS00WiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/file-pen-line
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const FilePenLine = createLucideIcon('FilePenLine', [
  ['path', { d: 'm18 5-3-3H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2', key: 'h0fsxq' }],
  ['path', { d: 'M8 18h1', key: '13wk12' }],
  ['path', { d: 'M18.4 9.6a2 2 0 1 1 3 3L17 17l-4 1 1-4Z', key: 'dyo8mm' }],
]);

export default FilePenLine;
